import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { navigate } from 'gatsby'
import { getFooterBanner } from '../../services/api'
import { breakpoints } from '../../styles/variables'

const Container = styled.button`
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 60px auto;
  border: none;
  padding: 0px;
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
    margin: 50px auto;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100vw;
    margin: 40px 0;
  }
`

const ImageBanner = styled.img`
  height: 450px;
  width: 100%;
  justify-content: center;
  align-content: center;
  object-fit: fill;
  overflow: hidden;
  @media ${`(max-width: ${breakpoints.md}px)`} {
    height: auto;
    max-height: 185px;
    width: 100vw;
  }
`

interface Props { }

//@ts-ignore
const FooterBanner: React.FC<Props> = () => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md })
  const [banner, setBanner] = useState({ mobileURL: '', desktopURL: '', linkURL: '' })
  const [bannerFetched, setBannerFetched] = useState(false)

  useEffect(() => {
    getFooterBanner().then((response) => {
      getBannerData(response.data)
    })
  }, [])

  const getBannerData = (bannerData: any) => {
    if (!!bannerData.mobileURL || !!bannerData.desktopURL) {
      const { mobileURL, desktopURL, linkURL } = bannerData
      setBanner({
        mobileURL,
        desktopURL,
        linkURL,
      })
      setBannerFetched(true)
    }
  }

  const imageSrc = isMobile ? banner.mobileURL : banner.desktopURL

  return (
    bannerFetched && (
      <Container onClick={() => navigate(banner.linkURL)}>
        <ImageBanner src={imageSrc} />
      </Container>
    )
  )
}

export default FooterBanner
