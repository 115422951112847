import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { navigate } from 'gatsby'
import { getBannersForPage } from '../../services/api'
import { breakpoints } from '../../styles/variables'

const Container = styled.button`
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 80px auto;
  border: none;
  padding: 0px;
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
    margin: 70px auto;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100vw;
    margin: 40px 0;
  }
`

const ImageBanner = styled.img`
  height: 250px;
  width: 100%;
  justify-content: center;
  align-content: center;
  object-fit: fill;
  overflow: hidden;
  @media ${`(max-width: ${breakpoints.md}px)`} {
    height: auto;
    max-height: 185px;
    width: 100vw;
  }
`

interface Props {
  page: string
  slot: number
}

//@ts-ignore
const DynamicBanner: React.FC<Props> = (props) => {
  const { page, slot } = props
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md })
  const [banner, setBanner] = useState({ mobileURL: '', desktopURL: '', linkURL: '' })
  const [bannerFetched, setBannerFetched] = useState(false)

  useEffect(() => {
    // Tää muuttuu kun adminissa footer banner hallinta valmis
    getBannersForPage(page).then((response) => {
      getBannerData(response.data.assignments)
    })
  }, [])

  const getBannerData = (bannerData: any) => {
    // @ts-ignore
    if (bannerData) {
      // @ts-ignore
      const banner = bannerData.find((a) => a.slot === slot)

      if (banner) {
        const { mobileURL, desktopURL, linkURL } = banner.banner
        setBanner({
          mobileURL,
          desktopURL,
          linkURL,
        })
        setBannerFetched(true)
      }
    }
  }

  const imageSrc = isMobile ? banner.mobileURL : banner.desktopURL

  return (
    bannerFetched && (
      <Container onClick={() => navigate(banner.linkURL)}>
        <ImageBanner src={imageSrc} />
      </Container>
    )
  )
}

export default DynamicBanner
