import * as React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import Container from '../components/Container'
import { Category } from '../components/Category'
import { getCategoriesForPage } from '../services/api'
import { useState } from 'react'
import FooterBanner from '../components/DynamicBanner/FooterBanner'
import DynamicBanner from '../components/DynamicBanner/DynamicBanner'

const IndexPageContainer = styled(Page)`
  padding: 0;
`

const IndexContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`

const IndexPage = () => {
  const [categoryElements, setCategoryElements] = useState([])
  const [categoryFetched, setCategoryFetched] = useState(false)

  if (!categoryFetched) {
    setCategoryFetched(true)
    getCategoriesForPage('index').then((data) => {
      console.log('OUR DEIDA', data.data)

      const parsedCategories = []

      for (let category of data.data) {
        parsedCategories.push({
          id: category.id,
          title: category.name,
          description: category.description,
          type: category.type
        })
      }

      // @ts-expect-error
      setCategoryElements(parsedCategories)
    })
  }

  return (
    <IndexPageContainer>
      <IndexContainer>
        {categoryElements.map((element, index) => {
          return (
            <div key={`${index}-${element.id}`}>
              <Category title={element.title} description={element.description} type={element.type} categoryIndex={index} />
              {index === 1 && <DynamicBanner page="index" slot={1} />}
              {index === 4 && <DynamicBanner page="index" slot={2} />}
            </div>
          )
        })}
        <FooterBanner />
      </IndexContainer>
    </IndexPageContainer>
  )
}
export default IndexPage
